<template>
  <div>
    <el-drawer class="assignOrderDetail-drawer" title="团队详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="() => { drawer = false }">
      <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
            <div class="detail-title">
                <div class="tit-info">
                    <div class="orderName">{{ detailInfo.teamName || "--" }}</div>
                    <div v-if="detailInfo.contactState == '已签约'" class="common-tableStatus-blue">已签约</div>
                    <div v-if="detailInfo.contactState == '未联系'" class="common-tableStatus-red">未联系</div>
                    <div v-if="detailInfo.contactState == '流失'" class="common-tableStatus-grey">流失</div>
                    <div v-if="detailInfo.contactState == '签约中'" class="common-tableStatus-green">签约中</div>
                </div>
            </div>
            <div class="detail-content">
                <el-form>
                    <div class="DisableEdit">签约类型：<span class="common-clr-0a6">{{detailInfo.contractType || '--'}}</span></div>
                    <div class="DisableEdit">姓名：<span class="common-clr-0a6">{{detailInfo.account || '--'}}</span></div>
                    <div class="DisableEdit">联系方式：<span class="common-clr-0a6">{{detailInfo.phone || '--'}}</span></div>
                    <div class="DisableEdit ">所在城市：<span class="common-clr-0a6">{{detailInfo.address || '--'}}</span></div>
                    <div class="DisableEdit">服务项目：<span class="common-clr-0a6">{{detailInfo.serviceProject || '--'}}</span></div>
                    <!-- <div class="detail-item">跟进入驻：<span>{{detailInfo.contactState || '--'}}</span></div> -->

                    <el-form-item v-if="editParams.filedName == 'contactState'" class="edit-input-container" label="跟进状态:">
                        <el-select class="edit-input" v-model="detailInfo.contactState" ref="contactState" placeholder="请选择" @blur="() => {editCancelBtn('contactState')}" @change="(e) => {editSettled('contactState', e)}" filterable >
                            <el-option label="未联系" value="0"></el-option>
                            <el-option label="签约中" value="1"></el-option>
                            <el-option label="已签约" value="2"></el-option>
                            <el-option label="流失" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="editParams.filedName != 'contactState'" @click="() => {editBtn('contactState', detailInfo.contactState)}" class="hoverEdit" >
                        跟进状态:
                        <span v-if="detailInfo.contactState == 0" class="editColor">未联系</span>
                        <span v-if="detailInfo.contactState == 1" class="editColor">签约中</span>
                        <span v-if="detailInfo.contactState == 2" class="editColor">已签约</span>
                        <span v-if="detailInfo.contactState == 3" class="editColor">流失</span>
                    </div>
                    <div class="detail-remark"><span>团队介绍：</span><span>{{detailInfo.teamIntroduce || '--'}}</span></div>
                </el-form>
            </div>
            <div class="upload-container">
                <div class="content-title">服务案例</div>
                <div v-if="fileList && fileList.length" class="fileList-container">
                    <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                        <div class="fileItem-name">
                            <common-picture :fileUrl="item" :fileType="Config.fileType.imgType.includes(item.split('.')[item.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item"></common-picture>
                        </div>
                        <div class="fileItem-btn-Container">
                            <div class="fileItemBtn" @click="() => {download(item)}">下载</div>
                        </div>
                    </div>
                </div>
                <div v-if="!fileList || !fileList.length" class="fileList-container">
                    <div class="fileItem">
                        暂无附件
                    </div>
                </div>
            </div>
            <div class="detail-content">
                <div class="content-title" style="margin-bottom: 16px">服务经验</div>
                <div class="DisableEdit" style="width: 40%;padding-left: 0">服务平台：<span class="common-clr-0a6">{{detailInfo.servicePlatform || '--'}}</span></div>
                <div class="DisableEdit" style="width: 40%;padding-left: 0">服务类目：<span class="common-clr-0a6">{{detailInfo.serviceCategory || '--'}}</span></div>
                <div class="DisableEdit" style="width: 40%;padding-left: 0">服务品牌：<span class="common-clr-0a6">{{detailInfo.serviceBrand || '--'}}</span></div>
            </div>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="跟进记录" name="1">
              <div>
                <textarea class="common-textarea-log"  @paste="handlePaste" v-model="recordContent" placeholder="跟进记录" rows="6"></textarea>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button @click="() => {addRecord()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <setted-log :followRecordList="followRecordList"></setted-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { Config, DateTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { settledDetail, workRecord, addRecord, editSettled } from "../../../service/manage.js"
import SettedLog from "./settedLog.vue"
export default {
    props: [ "handleClose"],
    components: { CommonPicture, SettedLog },
    data() {
        return {
            DateTransform,
            loading: false,
            drawer: false,
            activeName: '1',
            detailInfo: {}, // 团队详情
            Config,
            fileList:[],
            recordContent: '', // 工作小计
            filesList: [],  // 工作小计文件上传
            followRecordList: [], // 工作日志
            settledId: '', // 团队id
            isShowInput: '',
            editParams: {
                filedName: "",
                filedValue: "",
            },
        };
    },
    methods: {
        editBtn(name, value) {
            new Promise((resolve) => {
                this.editParams = {
                    filedName: name,
                    filedValue: value,
                };
                resolve()
            }).then(() => {
                if (this.$refs[name]) {
                    this.$refs[name].focus()
                }
            }).then(() => {
                this.isShowInput = name
            })

        },
        editCancelBtn(name) { // 取消编辑
            setTimeout(() => {
                if (this.isShowInput == name) {
                    this.editParams = {
                        filedName: "",
                        filedValue: "",
                    };
                    this.isShowInput = ''
                }
            }, 500)
        },
        async editSettled (type, val) {
            let params = {
                id: this.settledId,
                type: type,
                newView: val
            }
            console.log(params)
            let { code } = await editSettled(params)
            if ( code == 200 ) {
                this.$message.success("编辑成功")
                this.editParams = {
                    filedName: "",
                    filedValue: "",
                };
                this.isShowInput = ''
                this.$emit('settledList')
                this.settledDetail(this.settledId)
                this.workRecord(this.settledId)
            }
        },
        getDetailData(id) { // 获取团队id
            this.settledId = id
            this.settledDetail(id)
            this.workRecord(id)
            this.drawer = true
        },
        async settledDetail(id) { // 详情
            let { data, code } = await settledDetail({ id })
            if (code == 200) {
                if ( data.urlList ) {
                    this.fileList = data.urlList.split(',')
                } else {
                    this.fileList = []
                }
                if (data.contactState == '未联系') {
                    data.contactState = '0'
                    data.contactStateName = '未联系'
                }
                if (data.contactState == '联系中' || data.contactState == '签约中') {
                    data.contactState = '1'
                    data.contactStateName = '签约中'
                }
                if (data.contactState == '已签约') {
                    data.contactState = '2'
                    data.contactStateName = '已签约'
                }
                if (data.contactState == '流失' || data.contactState == '废弃') {
                    data.contactState = '3'
                    data.contactStateName = '流失'
                }
                this.detailInfo = data
            }
        },
        handlePaste(event) {
        const items = (event.clipboardData || window.clipboardData).items;
        let file = null;

        if (!items || items.length === 0) {
            this.$message.error("当前浏览器不支持本地");
            return;
        }
        // 搜索剪切板items
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
            file = items[i].getAsFile();
            break;
            }
        }
        if (!file) {
            return;
        }
        this.chooseFile(file, 'copy')
        },
        download(url) { // 下载
            window.location.href = url
        },
        async addRecord() { // 工作记录发布按钮
            let filesList = [...this.filesList]
            let filesUrl = []
            filesList.map((item) => {
                filesUrl.push(item.fileUrl)
            })
            let params = {
                serviceWebsiteId: this.settledId,
                content: this.recordContent,
                url: filesUrl.join(','),
            };
            this.loading = true
            let { code } = await addRecord(params)
            this.loading = false
            if (code == 200) {
                this.workRecord(this.settledId)
                this.filesList = []
                this.recordContent = "";
                this.$message.success("添加成功");
            }
        },
        async workRecord(id) { // 工作日志
            let { data } = await workRecord({id: id})
            if (data && data.length) {
                data.map((item) => {
                if (item.value) {
                    item.value = JSON.parse(item.value)
                } else {
                    item.value = []
                }
                })
            }
            this.followRecordList = [...data]
        },
        deleteFile (id) { // 删除工作日志文件
            let filesList = [...this.filesList]
            this.filesList = filesList.filter((item)=>{
                return item.fileId != id
            })
        },
        async chooseFile(e, type) { // 添加工作日志文件
            let file
            if (type != 'copy') {
                file = e.target.files[0];
            } else {
                file = e
            }
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                if (type != 'copy') { e.target.value = "" }
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                if (type != 'copy') { e.target.value = "" }
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let resData = (await upload({ file: file })).data;
            this.loading = false
            let fileItem = {
                fileType: "",
                fileName: file.name,
                fileId: resData.id,
                fileUrl: resData.filename,
            };
            if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
                fileItem.fileType = "img";
            }
            this.filesList.push(fileItem);
            if (type != 'copy') { e.target.value = "" }
        },
  },
};
</script>
<style lang="less" scoped>
.content-title {
    font-size: 18px;
    margin-top: 40px;
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
    font-size: 12px;
    color: #333;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
    .deleteBtn {
    color: #1890ff;
    cursor: pointer;
    }
  }
}
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.assignOrderDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .upload-container {
        // margin-top: 48px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
      }
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .handle-btn {
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        color: #fff;
        background: #1890FF;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        cursor: pointer;
        margin-top: 24px;
      } 
      .detail-title {
        line-height: 78px;
        .tit-info {
            display: flex;
            align-items: center;
            .orderName {
                color: #101010;
                font-size: 18px;
                margin-right: 16px;
            }
        }
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 100%;
        .edit-input {
            width: 200px;
            height: 32px;
            line-height: 32px;
            vertical-align: top;
        }
        .hoverEdit {
            display: inline-block;
            width: 323px;
            height: 21px;
            padding: 6px 12px;
            font-size: 12px;
            color: #999;
            margin-right: 16px;
            border-radius: 4px;
            vertical-align: top;
            cursor: pointer;
            .editColor {
                color: #1890ff;
            }
            &:hover {
                background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center #f2f9ff;
            }
        }
        .edit-input-container {
            display: inline-block;
            width: 323px;
            height: 32px;
            padding: 0 12px;
            font-size: 12px;
            color: #999;
            margin-right: 16px;
            margin-bottom: 0;
            border-radius: 4px;
            /deep/ .el-form-item__label {
                line-height: 32px;
            }
        }
        .DisableEdit {
            display: inline-block;
            width: 323px;
            height: 21px;
            padding: 6px 12px;
            font-size: 12px;
            color: #999;
            margin-right: 16px;
            border-radius: 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        margin-bottom: 16px;
        padding: 0 12px;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
  .pay{
    margin-left: 16px;
  }
}
</style>
