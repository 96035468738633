<template>
    <div class="commonWorkLog-container">
        <div v-if="!followRecordList || !followRecordList.length">暂无记录</div>
        <div v-else class="record-container">
            <div v-for="(item, index) in followRecordList" :key="index" class="record-item">
                <div class="item-top">
                    <div class="item-icon"></div>
                    <span class="item-role"> {{ item.createBy || '--' }} </span>
                    <span class="item-date"> {{ item.createTime || '--'}} </span>
                </div>
                <div>
                    <div class="item-content">
                        <div class="item-detail" v-if="typeof(item.url) == 'undefined'">{{item.content}}</div>
                        <div class="item-detail" v-else>说明：{{item.content}}</div>
                        <div v-if="item.url">
                            <div v-for="(item, index) in item.url.split(',')" :key="index">
                                <div class="item-detail download-container">
                                    <common-picture :fileUrl="item" :fileType="Config.fileType.imgType.includes(item.split('.')[item.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item"></common-picture>
                                    <div class="downloadBtn" @click="() => { downLoad(item) }">下载</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <br />
                <br />
            </div>
        </div>
    </div>
</template>
<script>
import { DateTransform, Config } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
    components: { CommonPicture },
    props: ["followRecordList"],
    name: 'commonWorkLog',
    data() {
        return {
            Config,
            DateTransform
        }
    },
    methods: {
        downLoad(url) { // 下载文件
            // window.location.href = url;
            window.open(url)
        },
    }
}
</script>
<style lang="less" scoped>
.commonWorkLog-container {
    flex: 1;
    overflow: hidden;
    .record-container {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        .record-item {
            margin-top: 16px;
            border-top: 1px #eaeaeb dashed;
            padding-top: 16px;
            .fileNames {
                color: #1890ff;
                margin-top: 8px;
                word-break: break-all;
                cursor: pointer;
            }
            .fileImg {
                width: 80px;
                margin-top: 8px;
                cursor: pointer;
            }
            .item-content {
                margin-left: 22px;
                .itemBtn {
                    display: inline-block;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 14px;
                    border: 1px solid #1890ff;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #101010;
                    margin: 16px 16px 8px 0;
                }
                .item-detail {
                    color: #333;
                    margin-top: 8px;
                    vertical-align: middle;
                    white-space: pre;
                    .visitIcon {
                    width: 14px;
                    margin-left: 8px;
                    }
                }
                .download-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-right: 16px;
                    .downloadBtn {
                        color: #1890ff;
                        cursor: pointer;
                        font-size: 12px;
                    }
                }
            }
            .item-top {
                padding-right: 16px;
                .item-icon {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    background-color: #fff;
                    border: 4px #1890ff solid;
                    border-radius: 50%;
                    margin-right: 8px;
                }
                .item-role {
                    color: #1890ff;
                }
                .item-date {
                    float: right;
                    color: #999;
                }
            }
        }
    }
}
</style>