import fetch from "./xhr/index";

/**
 * 上传文件
 * @param
 */
export const uploadUserXslx = param => fetch("upload", "/admin/uploadUserFile", param);

/**
 * 员工列表汇总-汇总弹窗
 * @param
 */
export const userSumList = param => fetch("GET", "/admin/getReportList", param);

/**
 * 员工离职并删除
 * @param
 */
export const quitCommmit = param => fetch("POST", "/ums/umsAdminQuit/quit", param);

/**
 * 离职员工列表
 * @param
 */
export const quitList = param => fetch("POST", "/ums/umsAdminQuit/quitList", param);

/**
 * 获取员工列表
 * @param
 */
export const getUserList = param => fetch("POST", "/admin/list", param);

/**
 * 获取指定用户信息
 * @param
 */
export const getUserInfo = (param, routeParam) => fetch("GET", `/admin/userinfo/${routeParam}`, param);

/**
 * 修改指定用户信息
 * @param
 */
export const updateUserInfo = (param, routeParam) => fetch("POST", `/admin/update/${routeParam}`, param);

/**
 * 修改指定用户信息
 * @param
 */
export const deleteUserItem = (param, routeParam) => fetch("POST", `/admin/delete/${routeParam}`, param);

/**
 * 修改指定用户密码
 * @param
 */
export const updatePassword = (param, routeParam) => fetch("POST", `/admin/updatePassword/${routeParam}`, param);

/**
 * 新增用户
 * @param
 */
export const addUser = (param) => fetch("POST", `/admin/register`, param);

/**
 * 分公司列表
 * @param
 */
export const getCompanyList = (param) => fetch("GET", `/company/list`, param);

/**
 * 添加分公司
 * @param
 */
export const addCompany = (param) => fetch("POST", `/company/create`, param);

/**
 * 编辑分公司
 * @param
 */
export const editCompany = (param, routeParam) => fetch("POST", `/company/update/${routeParam}`, param);

/**
 * 删除分公司
 * @param
 */
export const deleteCompany = (param, routeParam) => fetch("POST", `/company/delete/${routeParam}`, param);

/**
 * 角色列表
 * @param
 */
export const getRoleList = (param) => fetch("GET", `/role/listAll`, param);

/**
 * 添加角色
 * @param
 */
export const addRole = (param) => fetch("POST", `/role/create`, param);

/**
 * 修改角色
 * @param
 */
export const editRole = (param, routeParam) => fetch("POST", `/role/update/${routeParam}`, param);

/**
 * 删除角色
 * @param
 */
export const deleteRole = (param, routeParam) => fetch("POST", `/role/delete/${routeParam}`, param);

/**
 * 获取所有权限菜单
 * @param
 */
export const getAllMenu = (param) => fetch("GET", `/role/listMenuAll`, param);

/**
 * 获取角色权限菜单
 * @param
 */
export const getRoleMenu = (param, routeParam) => fetch("GET", `/role/listMenu/${routeParam}`, param);

/**
 * 设置角色权限菜单
 * @param
 */
export const commitMenu = (param) => fetch("POST", `/role/allocMenu`, param);

/**
 * 部门列表
 * @param
 */
export const departList = (param) => fetch("GET", `/depart/list`, param);

/**
 * 新增部门
 * @param
 */
export const addDepart = (param) => fetch("POST", `/depart/create`, param);

/**
 * 删除部门
 * @param
 */
export const deleteDepart = (param, routeParam) => fetch("GET", `/depart/delete/${routeParam}`, param);

/**
 * 设置部门
 * @param
 */
export const setupDepart = (param, routeParam) => fetch("POST", `/depart/update/${routeParam}`, param);

/**
 * 平台----------公司版块查询
 * @param
 */
 export const comPlate = (param) => fetch("GET", `/ums/umsCompanyPlate/list`, param);

/**
 * 平台----------配置公司版块
 * @param
 */
export const plateEdit = (param) => fetch("POST", `/ums/umsCompanyPlate/plateEdit`, param);
 
/**
 * 平台----------编辑公司
 * @param
 */
 export const editComPlate = (param) => fetch("POST", `/company/createCompany`, param);

 /**
  * 平台----------公司列表
  * @param
  */
 export const comListNew = (param) => fetch("GET", `/company/listNew`, param);

/**
 * 公司-详情接口
 * @param
 */
export const companyDetail = (param, routeParam) => fetch("POST", `/company/details/${routeParam}`, param);

/**
 * 公司-编辑
 * @param
 */
export const editCompanyDetail = (param) => fetch("GET", `/company/list/update`, param);

/**
 * 公司-上传/删除附件
 * @param
 */
export const companyFile = (param) => fetch("GET", `/company/file/update`, param);

/**
 * 公司-上传/删除附件
 * @param
 */
export const workLog = (param) => fetch("GET", `/ums/umsAdminUpdateRecord/company`, param);

/**
 * 平台公司-列表
 * @param
 */
export const companyList = (param) => fetch("GET", `/company/listCompany`, param);

/**
 * 平台公司-邀请码
 * @param
 */
export const shareCode = (param) => fetch("POST", `/ums/umsCompanyCode/companyCode`, param);

/**
 * 调查问卷统计
 * @param
 */
export const statistical = (param) => fetch("GET", `/ums/umsQuestionnaire/statistical`, param);
 
/**
 * 店铺关联订单列表
 * @param
 */
export const shopOrderList = (param) => fetch("GET", `/cms/cmsSubaccountManage/shopOrderList`, param);

/**
 * 官网入驻列表
 * @param
 */
export const settledList = (param) => fetch("GET", `/sys/sysServiceWebsite/list`, param);

/**
 * 官网入驻-详情
 * @param
 */
export const settledDetail = (param) => fetch("GET", `/sys/sysServiceWebsite/details`, param);

/**
 * 官网入驻-工作记录查询
 * @param
 */
export const workRecord = (param) => fetch("GET", `/sys/sysServiceWebsite/messageBoardList`, param);

/**
 * 官网入驻-添加工作记录
 * @param
 */
export const addRecord = (param) => fetch("POST", `/sys/sysServiceWebsite/messageBoard`, param);

/**
 * 官网入驻-编辑
 * @paramS
 */
export const editSettled = (param) => fetch("POST", `/sys/sysServiceWebsite/updateServiceWebsite`, param);

/**
 * 
 * @param
 */
export const selectWorkOrderList = (param) => fetch("GET", `/cms/workOrder/selectWorkOrderList`, param);

/**
 * 删除员工时修改流程审核人
 * @param
 */
export const updateApproveId = (param) => fetch("GET", `/cms/workOrder/updateApproveId`, param);

/**
 * 品牌方注册接口
 * @param
 */
export const registerBrand = (param) => fetch("POST", `/admin/registerBrand`, param);